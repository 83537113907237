.textFormField {
  width: 260px !important;
}

button:hover {
  color: #FFFFFF;
  background-color: #4B8596 !important;
}

button:active {
  color: #FFFFFF;
  background-color: #4B8596 !important;
}

button:disabled {
  color: #FFFFFF;
  background-color: #919b9e !important;
}

.MuiFormControl-root.MuiTextField-root.DOBInputField.css-5412dz-MuiFormControl-root-MuiTextField-root:hhover {
  border: none;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border: none;
  color: #00000099 !important;
}

input {
  color: #00000099 !important;
}

textarea {
  color: #00000099 !important;
}

