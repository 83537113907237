.indexedStep {
  color: #bebebe;
  width: 30px;
  height: 30px;
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.indexedStep.accomplished {
  background-color: #1B405A;
  color: white;
  border-style: none;
}

.RSPBprogressBar {
  width: 13px;
  line-height: 13;
  border-radius: 10px;
  position: relative;
  background-color: white;
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.RSPBprogressBar .RSPBstep {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: absolute;
  transform: translateX(-50%);
  transition-property: all;
  transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
  color: white;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.RSPBprogressBar .RSPBprogression {
  position: absolute;
  transition: width 0.3s ease;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  background: #1B405A;
  z-index: -1;
}

@media screen and (max-width: 480px) {
  .indexedStep{
      width: 15px;
      height: 15px;
      font-size: 6px;
  }
}